<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-popup
      class="holamundo"
      title="تغيير حالة المعاملة"
      :active.sync="popupActive"
    >
      <div class="popup-style w-full h-full flex flex-col justify-between">
        <div>
          <label for="status" class="w-full mb-1">حالة المعاملة</label>
          <v-select
            :options="['قيد المراجعة', 'مرفوضة', 'تمت الموافقة']"
            :value="toEdit.status"
            v-model="toEdit.status"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
          ></v-select>
        </div>
        <div class="w-full text-center">
          <vs-button
            class="rounded mx-3"
            color="danger"
            type="border"
            @click="togglePopup"
            >إلغاء</vs-button
          >
          <vs-button
            class="rounded mx-3"
            color="primary"
            @click="editTransaction"
            >تعديل</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <div id="transactionbar">
      <vs-sidebar
        position-right
        parent="body"
        click-not-close
        default-index="1"
        color="primary"
        class="sidebarx mysidebar"
        v-model="SidebarActive"
      >
        <div class="header-sidebar py-3 px-3" slot="header">
          <h4 class="flex items-center justify-between">
            إضافة معاملة
            <span class="material-icons cursor-pointer" @click="toggleSidebar">
              close
            </span>
          </h4>
        </div>
        <div class="vx-row pt-1">
          <div class="vx-col w-1/4 ml-5">
            <label for="invoice_id" class="text-primary">رقم الفاتورة</label>
          </div>
          <div class="vx-col w-3/5">
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              label="id"
              :options="invoices"
              name="invoice_id"
              class="w-full"
              v-validate="'required'"
              data-vv-as="رقم الفاتورة"
              v-model="new_transaction.id"
              :reduce="(invoice) => invoice.id"
              @input="getUser()"
            ></v-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('invoice_id')"
              >{{ errors.first("invoice_id") }}</span
            >
          </div>
          <div class="vx-col w-full ml-4 mt-4">
            <span v-if="invoice_user" class="text-warning"
              >هذه الفاتورة للمستخدم:
              {{ this.invoice_user }}
            </span>
          </div>
        </div>

        <vs-divider> </vs-divider>

        <div class="vx-row pt-1">
          <div class="vx-col w-2/4 ml-5">
            <label for="date" class="text-primary">تاريخ المعاملة</label>
          </div>
          <div class="vx-col w-3/5">
            <vs-input
              data-vv-as="تاريخ المعاملة"
              type="date"
              class="w-full"
              name="creation"
              v-validate="'required'"
              v-model="new_transaction.date"
            />

          <span class="text-danger text-sm" v-show="errors.has('creation')">{{
            errors.first("creation")
          }}</span>
          </div>
        </div>

        <vs-divider> </vs-divider>

        <div class="vx-row pt-1">
          <div class="vx-col w-1/4 ml-5">
            <label for="payment_method" class="text-primary">وسيلة الدفع</label>
          </div>
          <div class="vx-col w-3/5">
            <v-select
              disabled
              :value="'ســداد'"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            ></v-select>
          </div>
        </div>

        <vs-divider> </vs-divider>

        <div class="vx-row pt-1">
          <div class="vx-col w-2/4 ml-5">
            <label for="ref_number" class="text-primary">الرقم المرجعي</label>
          </div>
          <div class="vx-col w-3/5">
            <vs-input

              v-validate="'required'"
              data-vv-as="الرقم المرجعي"
              name="ref_num"
             v-model="new_transaction.reference_number" type="text" />

          <span class="text-danger text-sm" v-show="errors.has('ref_num')">{{
            errors.first("ref_num")
          }}</span>
          </div>

        </div>

        <vs-divider> </vs-divider>

        <div class="vx-row pt-1">
          <div class="vx-col w-2/4 ml-3">
            <label for="paid_amount" class="text-primary"
              >القيمة المدفوعة</label
            >
          </div>
          <div class="vx-col w-3/5">
            <vs-input
              v-model="new_transaction.paid_amount"
              type="number"
              name="paid_amount"
              icon="attach_money"
              v-validate="'required|numeric'"
              data-vv-as="القيمة المدفوعة"
            />
          <span class="text-danger text-sm" v-show="errors.has('paid_amount')">{{
            errors.first("paid_amount")
          }}</span>
          </div>
        </div>
        <vs-divider> </vs-divider>
        <div class="vx-row pt-1">
          <div class="vx-col w-1/4 ml-5">
            <label for="invoice_id" class="text-primary">المتجر</label>
          </div>
          <div class="vx-col w-3/5">
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              label="name"
              :options="stores"
              scroll
              name="store"
              class="w-full"
              v-validate="'required'"
              data-vv-as="إسم المتجر"
              v-model="new_transaction.store_id"
              :reduce="(store) => store.id"
            ></v-select>
          <span class="text-danger text-sm" v-show="errors.has('store')">{{
            errors.first("store")
          }}</span>
          </div>
        </div>
        <vs-divider> </vs-divider>

        <div class="vx-row pt-1">
          <div class="vx-col w-1/4 ml-5">
            <label for="status" class="text-primary">حالة المعاملة</label>
          </div>
          <div class="vx-col w-3/5">
            <v-select
              :options="['قيد المراجعة', 'مرفوضة', 'تمت الموافقة']"
              v-model="new_transaction.status"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="status"
              v-validate="'required'"
              data-vv-as="حالة المعاملة"
            ></v-select>

          <span class="text-danger text-sm" v-show="errors.has('status')">{{
            errors.first("status")
          }}</span>
          </div>
        </div>
        <vs-divider> </vs-divider>

        <div class="py-4 px-4" style="text-align: left" slot="">
          <vs-button
            class="rounded mr-6"
            color="danger"
            type="border"
            @click="toggleSidebar"
            >إلغاء</vs-button
          >
          <vs-button @click="addTransaction()" class="rounded" color="primary"
            >إضافة</vs-button
          >
        </div>
      </vs-sidebar>
    </div>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        multiple
        v-model="selected"
        :max-items="itemsPerPage"
        search
        pagination
        :data="transactions"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          >
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer mr-4 mb-4"
            >
              <div
                class="
                  p-4
                  shadow-drop
                  rounded-lg
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                  text-lg
                  font-medium
                  w-32 w-full
                "
              >
                <span class="mr-2">العمليات</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>حذف</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="ArchiveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>أرشيف</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="FileIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>طباعة</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="SaveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>عمليات أخرى</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ADD NEW -->
            <vs-button
              @click="toggleSidebar"
              class="
                btn-add-new
                p-3
                mb-4
                mr-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">اضافة معاملة</span>
            </vs-button>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown
            vs-trigger-click
            class="cursor-pointer mb-4 mr-4 items-per-page-handler"
          >
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  transactions.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : transactions.length
                }}
                of {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="transaction_id">رقم الفاتورة</vs-th>
          <vs-th sort-key="transaction_date">تاريخ المعاملة</vs-th>
          <vs-th sort-key="user">المستخدم</vs-th>
          <vs-th sort-key="payment_method">طريقة الدفع</vs-th>
          <vs-th sort-key="ref_number">الرقم المرجعي</vs-th>
          <vs-th sort-key="paid_amount">المدفوع</vs-th>
          <vs-th sort-key="status">الحالة</vs-th>
          <vs-th>العمليات</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.id }}
                </p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.date }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.invoice.order.user.name }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">
                  {{ tr.invoice.payment_method.name }}
                </p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.reference_number }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ tr.paid_amount }}</p>
              </vs-td>

              <vs-td>
                <vs-chip
                  transparent
                  :color="getTransactionStatusColor(tr.status)"
                  class="product-order-status"
                >
                  {{ tr.status }}
                </vs-chip>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="editStatus(tr.id, tr.status)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import { mapActions } from "vuex";

// import moduleOrder from "@/store/orders/moduleOrder.js";
export default {
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      popupActive: false,
      SidebarActive: false,
      selected: [],
      transactions: [],
      invoices: [],
      stores: [],
      new_transaction: {
        paid_amount: "",
        id: "",
        date: "",
        status: "",
        store_id: "",
        reference_number: "",
      },
      toEdit: [], //the transaction that is going to be edited
      invoice_user: "", //it holds the name of the user which owns a specific invoice number
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
      order_id: "",
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.transactions.length;
    },
  },
  methods: {
    ...mapActions("transactions", [
      "fetchTransactions",
      "createTransaction",
      "fetchInvoices",
      "fetchPaymentMethods",
      "updateTransaction",
    ]),
    ...mapActions("stores", ["fetchStores"]),
    toggleSidebar() {
      this.SidebarActive = !this.SidebarActive;
    },
    togglePopup() {
      this.popupActive = !this.popupActive;
    },
    getData() {
      this.$vs.loading();
      this.fetchTransactions().then((response) => {
        this.transactions = response.data;
        this.$vs.loading.close();
      });
      this.fetchStores().then((response) => {
        this.stores = response.data.data;
      });
      this.fetchInvoices().then((response) => {
        this.invoices = response.data;
      });
    },
    toEnglishStatus(status){
      switch(status){
        case 'قيد المراجعة': return 'under_review';
        case 'مرفوضة': return 'cancelled';
        case 'مؤكدة': return 'confirmed';
      }
    },
    getUser() {
      this.invoices.filter((invoice) => {
        if (invoice.id == this.new_transaction.id) {
          this.invoice_user = invoice.user;
        }
      });
    },
    async addTransaction() {
      let result = await this.$validator.validateAll().then((result) => {
        return result;
      });

      if (result == false) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يرجى ملئ كافة الحقول",
          color: "danger",
        });
        return false;
      }
      //creating a transaction here

      var bodyFormData = new FormData();
      bodyFormData.set("paid_amount", this.new_transaction.paid_amount);
      bodyFormData.set("invoice_id", this.new_transaction.id);
      bodyFormData.set("date", this.new_transaction.date);
      bodyFormData.set("status", this.toEnglishStatus(this.new_transaction.status));
      bodyFormData.set("store_id", this.new_transaction.store_id);
      bodyFormData.set(
        "reference_number",
        this.new_transaction.reference_number
      );
      this.createTransaction(bodyFormData)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت إضافة معاملة مالية بنجاح",
            color: "success",
          });
          this.toggleSidebar();
          this.getData();
        })
        .catch((error) => {
          let errorMessage = error.response.data.errors
                ? error.response.data.errors
                : error.message;
          this.errorDialog(errorMessage)
        });
    },
    editStatus(id, status) {
      this.togglePopup();
      this.toEdit = { id, status };
    },
    editTransaction() {
      var bodyFormData = new FormData();
      bodyFormData.set("id", this.toEdit.id);
      bodyFormData.set("status", this.toEnglishStatus(this.toEdit.status));
      this.updateTransaction(bodyFormData)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت تعديل معاملة مالية بنجاح",
            color: "success",
          });
          this.togglePopup();
          this.getData();
        })
        .catch((error) => {
         this.errorDialog(error)
        });
    },
// gets transaction status color.
    getTransactionStatusColor(status) {
      if (status == "قيد المراجعة") return "warning";
      if (status == "مؤكدة") return "success";
      if (status == "مرفوضة") return "danger";
      return "primary";
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    this.getData();
    // if (!moduleOrder.isRegistered) {
    //   this.$store.registerModule("order", moduleOrder);
    //   moduleOrder.isRegistered = true;
    // }
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

//sidebar
.vs-sidebar--background {
  z-index: 99999 !important;
}

.vs-sidebar-position-right {
  z-index: 999999 !important;
  max-width: 400px !important;
}
.vs-sidebar-primary .vs-sidebar-item-active {
  font-weight: 400 !important;
  border: none !important;
}
.vs-con-input {
  i {
    font-size: 1.6rem !important;
  }
}

//popup
.popup-style {
  height: 200px !important;
}
.mysidebar {
  overflow-y: scroll;
}
</style>
